<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">合伙人昵称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入合伙人昵称"
            v-model.trim="searchValue.keyword"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="10">
          <el-date-picker
            value-format="timestamp"
            v-model="date"
            type="daterange"
            :default-time="defaultTime"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker
        ></el-col>

        <el-col :span="6">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="index" label="排名"> </el-table-column>
        <el-table-column align="center" prop="id" label="用户ID"> </el-table-column>
        <el-table-column align="center" prop="name" label="用户昵称"> </el-table-column>
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="phoneNumber" label="联系方式"> </el-table-column>
        <el-table-column align="center" prop="money" label="分销收益"> </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'

export default {
  mixins: [pagination],
  data() {
    return {
      defaultTime: ['00:00:00', '23:59:59'],
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return (
            time.getTime() >= new Date(new Date(new Date(Date.now()).toISOString().substr(0, 10)) - 8 * 60 * 60 * 1000)
          )
        }
      },
      searchValue: { keyword: '', endTime: '', startTime: '' },
      list: [],
      date: [
        new Date(new Date(Date.now() - 3600 * 1000 * 24 * 7).toISOString().substr(0, 10)).getTime() - 8 * 3600 * 1000,
        new Date(new Date(Date.now() - 3600 * 1000 * 24 - 1).toISOString().substr(0, 10)).getTime() +
          16 * 3600 * 1000 -
          1
      ]
    }
  },
  methods: {
    resetSearch() {
      this.date = [
        new Date(new Date(Date.now() - 3600 * 1000 * 24 * 7).toISOString().substr(0, 10)).getTime() - 8 * 3600 * 1000,
        new Date(new Date(Date.now() - 3600 * 1000 * 24 - 1).toISOString().substr(0, 10)).getTime() +
          16 * 3600 * 1000 -
          1
      ]
      this.searchValue = { keyword: '', endTime: '', startTime: '' }
      this.getList()
    },
    // 请求后端获取分销排行列表
    getList(val) {
      this.loading = true
      this.searchValue.startTime = ''
      this.searchValue.endTime = ''
      if (this.date && this.date.length !== 0) {
        this.searchValue.startTime = this.date[0]
        this.searchValue.endTime = this.date[1]
      }
      this.currentPage = val === true ? this.currentPage : 1
      this.$api.distribution
        .distribution('distributionList', {
          params: {
            ...this.searchValue,
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.records.map((item, index) => {
            item.index = (parseInt(res.current) - 1) * 10 + (index + 1)
            return item
          })
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {}
}
</script>

<style scoped lang="less">
.avatar {
  height: 50px;
  width: 50px;
}
</style>
